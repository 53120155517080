import React from "react";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";

const ServiceOneAll = () => {
  return (
    <>
      {/* service area start */}
      <div className='service-area style-2 pd-top-115 pd-bottom-80'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-7'>
              <div className='section-title text-center'>
                <h4 className='subtitle'>HİZMETLERİMİZ</h4>
                <h2 className='title'>LOJİSTİK ÇÖZÜMLERİMİZ</h2>
                <p>
                  Lojistik Merkezi olarak, karayoluyla hızlı, esnek ve ekonomik taşımacılık hizmetleri sunuyoruz. İhtiyaçlarınıza özel çözümler için bize ulaşın!{" "}
                </p>
              </div>
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-lg-4'>
              <div className='single-service-wrap'>
                <div className='thumb'>
                  <img src='assets/img/service/1.jpeg' alt='Lojistik Merkezi' />
                  <div className='icon'>
                    <img
                      src='assets/img/service/service-icon-1.png'
                      alt='Lojistik Merkezi'
                    />
                  </div>
                </div>
                <div className='details'>
                  <h5>Parsiyel Taşımacılık</h5>
                  <p>
                    Lojistik Merkezi olarak, adrese teslim Parsiyel Taşimacılık hizmeti sunuyoruz.{" "}  
                  </p>
                  <div className='btn-wrap'>
                    <Link className='read-more-text' to='/parsiyel'>
                      DEVAMI{" "}
                      <span>
                        <FaArrowRight />
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='single-service-wrap'>
                <div className='thumb'>
                  <img src='assets/img/service/2.jpeg' alt='Lojistik Merkezi' />
                  <div className='icon'>
                    <img
                      src='assets/img/service/service-icon-2.png'
                      alt='Lojistik Merkezi'
                    />
                  </div>
                </div>
                <div className='details'>
                  <h5>Gümrükleme Hizmeti</h5>
                  <p>
                    Lojistik Merkezi olarak, gümrükleme hizmeti sunuyoruz.{" "}
                  </p>
                  <div className='btn-wrap'>
                    <Link className='read-more-text' to='/gumrukleme'>
                      DEVAMI{" "}
                      <span>
                        <FaArrowRight />
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='single-service-wrap'>
                <div className='thumb'>
                  <img src='assets/img/service/3.jpeg' alt='Lojistik Merkezi' />
                  <div className='icon'>
                    <img
                      src='assets/img/service/service-icon-3.png'
                      alt='Lojistik Merkezi'
                    />
                  </div>
                </div>
                <div className='details'>
                  <h5>Depolama Hizmeti</h5>
                  <p>
                    Lojistik Merkezi olarak, depolama hizmeti sunuyoruz.{" "}
                  </p>
                  <div className='btn-wrap'>
                    <Link className='read-more-text' to='/depolama'>
                      DEVAMI{" "}
                      <span>
                        <FaArrowRight />
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          
          </div>
        </div>
      </div>
      {/* service area end */}
    </>
  );
};

export default ServiceOneAll;
